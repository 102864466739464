










































































































import {
  defineComponent,
  getCurrentInstance,
  PropType,
  computed,
  ref,
} from '@vue/composition-api'

import { State } from '@/types/entities'
import {
  AsyncStatusTypeDb,
  Maybe,
  UiSAsyncImportStatus,
  UiSAsyncImportStatusConnection,
} from '@/types/generated/graphql'
import { asyncStatusTypeDbMapping } from '@/utils/constant'
import { currencyFormatted } from '@/utils/mixin'

export default defineComponent({
  props: {
    type: {
      type: String as PropType<string>,
    },
    perPage: {
      type: Number,
    },
    items: {
      type: Object as PropType<UiSAsyncImportStatusConnection>,
    },
    selected: {
      type: String as PropType<string>,
      default: '',
    },
    userVal: {
      type: String as PropType<string>,
      default: '',
    },
    allVal: {
      type: String as PropType<string>,
      default: '',
    },
  },
  setup(props, context) {
    const instance = getCurrentInstance()
    if (!instance) {
      return
    }
    const { $i18n } = instance.proxy

    const currentPage = ref(1)

    const fields = [
      {
        key: 'index',
        label: 'No.',
        thClass: 'row-no',
        tdClass: 'row-no',
      },
      {
        key: 'screenName',
        label: $i18n.t('label.screenName'),
      },
      {
        key: 'createUserName',
        label: $i18n.t('label.operatedBy'),
        thClass: 'create-user-name',
      },
      {
        key: 'createDatetime',
        label: $i18n.t('label.requestedAt'),
        thClass: 'create-datetime',
      },
      {
        key: 'lastModifiedDatetime',
        label: $i18n.t('label.executedAt'),
        thClass: 'last-modified-datetime',
      },
      {
        key: 'status',
        label: $i18n.t('label.status'),
        thClass: 'status',
        tdClass: (value: string) =>
          isStatusError(value) ? 'status-error' : 'status',
      },
      {
        key: 'importResult',
        label: $i18n.t('label.importResult'),
        thClass: 'import-result',
      },
      {
        key: 'downloadFilename',
        label: $i18n.t('label.downloadFilename'),
        thClass: 'download-filename',
        tdClass: 'download-filename',
      },
      {
        key: 'operationId',
        label: $i18n.t('label.operationId'),
        thClass: 'operation-id',
        tdClass: 'operation-id',
      },
    ]

    const onChangePage = (v: number) => {
      context.emit('change-page', v)
    }

    const onUpdateImportHistory = () => {
      currentPage.value = 1
      context.emit('update-import-history')
    }

    const downloadUrl = (
      item: Maybe<UiSAsyncImportStatus>
    ): string | undefined => {
      const returnIfValidString = (
        source: Maybe<string> | undefined
      ): string | undefined => {
        if (typeof source === 'string' && source !== '') {
          return source
        }
      }

      if (item === null || item === undefined) {
        return
      }
      const state = asyncStatusTypeDbMapping[item.status]
      if (state == undefined) {
        return
      }
      // 処理が完了しているときに表示する
      if (state.isFinished) {
        if (state.isSucceeded) {
          // 処理が成功で完了しているとき
          return returnIfValidString(item.getFileurlSource)
        } else {
          // 処理が失敗で完了しているとき
          return (
            returnIfValidString(item.getFileurlValidatedTransformed) ||
            returnIfValidString(item.getFileurlSource)
          )
        }
      }
    }

    const displayTime = computed(() => {
      return (time: string) => {
        const d = new Date(time)
        const year = d.getFullYear()
        const month = (d.getMonth() + 1).toString().padStart(2, '0')
        const date = d.getDate().toString().padStart(2, '0')
        const hour = d.getHours().toString().padStart(2, '0')
        const minutes = d.getMinutes().toString().padStart(2, '0')
        return `${year}/${month}/${date} ${hour}:${minutes}`
      }
    })

    const downloadFile = (item: UiSAsyncImportStatus) => {
      const link = document.createElement('a')
      link.download = `id_${item.operationId}_${props.type}.csv`
      // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
      link.href = downloadUrl(item)!
      link.click()
    }

    const isStatusError = (value: string) => {
      for (const [k, v] of Object.entries(asyncStatusTypeDbMapping)) {
        if (k === value) {
          return v.isFinished && !v.isSucceeded
        }
      }
      return false
    }

    const displayStatus = computed(() => {
      return (item: Maybe<UiSAsyncImportStatus>) => {
        if (item?.status === undefined) {
          return $i18n.t('status.unknown')
        }
        if (asyncStatusTypeDbMapping[item.status] == null) {
          return $i18n.t('status.unknown') + '(' + item.status + ')'
        }
        const asyncStatusTypeDb = asyncStatusTypeDbMapping[item.status]
        const display = asyncStatusTypeDb.display
        if (display === 'status.errored') {
          return $i18n.t(display) + '(' + item.status + ')'
        } else {
          return $i18n.t(display)
        }
      }
    })

    const shouldShowLastModifiedDatetime = (
      node: Maybe<UiSAsyncImportStatus>
    ) => {
      const s = node?.status
      if (s === undefined) {
        return false
      }
      const status = asyncStatusTypeDbMapping[s]
      if (status === undefined) {
        return false
      }
      return status.isFinished && status.isSucceeded
    }

    const shouldShowDownloadlink = (
      node: Maybe<UiSAsyncImportStatus>
    ): boolean => {
      return typeof downloadUrl(node) === 'string'
    }

    const totalRowCount = () => {
      const rawValue = props.items?.edges?.length || 0
      return props.items?.pageInfo?.hasNextPage ? rawValue + 1 : rawValue
    }

    const row = () => {
      if (props.items?.edges === undefined) {
        return []
      }
      return props.items.edges.map((e, index) => {
        const label = e?.node?.label || ''
        return {
          index: index + 1,
          ...e?.node,
        }
      })
    }
    const rowColor = (item: any) => {
      if (item === null) {
        return []
      }
      const status = item.status
      if (isStatusError(status)) return 'table-danger'
    }
    const extractFilenameOnUploaded = (item: UiSAsyncImportStatus): string => {
      // 'import/2022/10/26/461f29a7__棚設定 - 208.csv' ->
      // '461f29a7__棚設定 - 208.csv'
      const result = item.filepathSource.split('/').pop()
      if (result !== undefined) {
        return result
      } else {
        return item.filepathSource // 本来返したい値とは異なるが空白を返すよりはうまく動作すると考えられるため、そのままの値を返す
      }
    }
    const allRadioVal = props.allVal
    const userRadioVal = props.userVal
    const selectedRadio = props.selected
    const changeExtractDisplay = (selected: string) => {
      // console.log('changeExtractSetting', { selected })
      const emitVal = selected === userRadioVal ? [selected] : []
      context.emit('change-extract-display', emitVal)
    }

    return {
      allRadioVal,
      userRadioVal,
      selectedRadio,
      changeExtractDisplay,
      currentPage,
      fields,
      downloadFile,
      displayStatus,
      displayTime,
      extractFilenameOnUploaded,
      onChangePage,
      onUpdateImportHistory,
      shouldShowLastModifiedDatetime,
      shouldShowDownloadlink,
      currencyFormatted,
      row,
      rowColor,
      totalRowCount,
      downloadUrl,
    }
  },
})
