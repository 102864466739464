














import { defineComponent, getCurrentInstance, ref } from '@vue/composition-api'
import { ApolloError, NetworkStatus } from 'apollo-client'
import dayjs from 'dayjs'

import ImportView from '@/components/templates/h/ImportView.vue'
import {
  WhereOp,
  // ResourceType,
  // SettingHelperDocument,
  OrderByDirection,
  Where,
  UiSAsyncImportStatusesDocument,
  // UiSAsyncExportStatusesDocument,
  UiSAsyncImportStatusesQuery,
  // UiSAsyncExportStatusesQuery,
} from '@/types/generated/graphql'
import { createApolloErrorMessage } from '@/utils/apiError'
import { setWhereValue, setWhereValues } from '@/utils/searchConditionDef'
import Toast, { genNotifyMessage } from '@/utils/toast'

export default defineComponent({
  components: { ImportView },
  layout: 'sidebar',
  apollo: {
    uiSAsyncImportStatuses: {
      query: UiSAsyncImportStatusesDocument,
      variables() {
        return {
          orderBy: [
            { field: 'createDatetime', direction: OrderByDirection.Desc },
          ],
          where: this._createWhere(),
          first: this.perPage,
        }
      },
      result(res, key) {
        this.ImportNetworkStatus = res.networkStatus
      },
      notifyOnNetworkStatusChange: true,
      error(e: ApolloError) {
        Toast.error(this, { message: createApolloErrorMessage(e) })
      },
    },
  },
  data() {
    return {
      allRadioVal: '',
      userRadioVal: this.$store.state.auth.loginUserInfo.username,
      selectedRadio: this.$store.state.auth.loginUserInfo.username,
      extractCondition: {
        createUser: this.$store.state.auth.loginUserInfo.username,
      },
      uiSAsyncImportStatuses: {
        edges: [],
        pageInfo: {
          hasNextPage: false,
          hasPreviousPage: false,
          startCursor: null,
          endCursor: null,
        },
      },
      loadedImPage: 1,
      perPage: 20,
      ImportNetworkStatus: NetworkStatus.ready,
    }
  },
  computed: {
    isLoading() {
      return this.ImportNetworkStatus !== NetworkStatus.ready
    },
  },
  methods: {
    changeExtractDisplay(param: string[]) {
      console.log('changeExtractCondition', param)
      this.extractCondition.createUser = param
      this.updateImportHistory()
    },
    /*********************************
     * インポート一覧用の処理
     *********************************/

    _createWhere() {
      // TODO: category0カラムが追加され次第抽出条件のbudgetGroupIdをcategory0に変更する
      const SEARCH_CONDITION: Where[] = [
        // 抽出条件
        {
          field: ['createUser'],
          op: WhereOp.In,
          value: [''],
        },
      ]
      const where = JSON.parse(JSON.stringify(SEARCH_CONDITION))
      setWhereValue(where, 'createUser', this.extractCondition.createUser)
      console.log({ where })
      return where
    },
    async changePageImportHistory(newPage: number) {
      console.log('changePageImportHistory', { newPage })
      if (this.loadedImPage < newPage) {
        try {
          await this.fetchMoreImportHistory()
          this.loadedImPage = newPage
        } catch (e: unknown) {
          Toast.error(this, {
            message: genNotifyMessage('e.list', 'import', e as ApolloError),
          })
        }
      }
    },
    updateImportHistory() {
      this.loadedImPage = 1
      console.log('event updateImportHistory')
      this.$apollo.queries.uiSAsyncImportStatuses.refetch()
    },
    async fetchMoreImportHistory() {
      await this.$apollo.queries.uiSAsyncImportStatuses.fetchMore({
        variables: {
          orderBy: [
            { field: 'createDatetime', direction: OrderByDirection.Desc },
          ],
          where: this._createWhere(),
          first: this.perPage,
          after: this.uiSAsyncImportStatuses.pageInfo.endCursor,
        },
        updateQuery: (
          previousResult: UiSAsyncImportStatusesQuery,
          { fetchMoreResult }: { fetchMoreResult: UiSAsyncImportStatusesQuery }
        ) => {
          const where = this._createWhere()
          return {
            uiSAsyncImportStatuses: {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              __typename: previousResult.uiSAsyncImportStatuses!.__typename,
              edges: [
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                ...previousResult.uiSAsyncImportStatuses!.edges,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                ...fetchMoreResult.uiSAsyncImportStatuses!.edges,
              ],
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              pageInfo: fetchMoreResult!.uiSAsyncImportStatuses!.pageInfo,
            },
          }
        },
      })
    },
  },
})
