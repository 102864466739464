





















import { ConsoleLogger } from '@aws-amplify/core'
import { defineComponent, PropType } from '@vue/composition-api'

import ImportHistoryTable from '@/components/organisms/h/page/import/ImportHistoryTable.vue'
import TitleAndManipulate from '@/components/organisms/h/TitleAndManipulate.vue'
import { ExportImportHistory } from '@/types/entities'
import {
  UiSAsyncImportStatusConnection,
  // UiAsyncImportStatusConnection,
} from '@/types/generated/graphql'

export default defineComponent({
  components: { ImportHistoryTable, TitleAndManipulate },
  props: {
    type: {
      type: String as PropType<string>,
      default: '',
    },
    isLoading: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    uiSAsyncImportStatuses: {
      type: Object as PropType<UiSAsyncImportStatusConnection>,
    },
    perPage: {
      type: Number as PropType<number>,
    },
    selectedRadio: {
      type: String as PropType<string>,
    },
    userRadioVal: {
      type: String as PropType<string>,
    },
    allRadioVal: {
      type: String as PropType<string>,
    },
  },
  setup(props, context) {
    const changePageImportHistory = (v: number) => {
      context.emit('change-page-import-history', v)
    }
    const updateImportHistory = () => {
      context.emit('update-import-history')
    }
    const allVal = props.allRadioVal
    const userVal = props.userRadioVal
    const selected = props.selectedRadio
    const changeExtractDisplay = (selected: string[]) => {
      const emitVal = selected
      // console.log(emitVal)
      context.emit('change-extract-display', emitVal)
    }
    return {
      allVal,
      userVal,
      selected,
      changeExtractDisplay,
      changePageImportHistory,
      updateImportHistory,
    }
  },
})
